<template>
  <el-dialog title="会员充值" :visible.sync="showRecharge" width="35%" :before-close="cancel">
    <el-form class="rechange" label-width="90px">
      <el-form-item label="当前余额：">{{ balance || '0' }}</el-form-item>
      <el-form-item label="充值数值：">
        <el-input v-model="rechange_num"></el-input>
      </el-form-item>
      <el-form-item label="备注：" class="message">
        <el-input v-model="log" type="textarea" :rows="8" placeholder="此备注会显示在用户记录和商家后台"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel()">取 消</el-button>
      <el-button type="primary" @click="comfirmRecharge">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ['showRecharge', 'balance', 'rechange_num', 'log', 'id', 'is_detail'],
  methods: {
    cancel() {
      this.$emit('closePopup');
    },
    comfirmRecharge() {
      if (!this.rechange_num)
        return this.$message({
          message: '请填写充值数值',
          type: 'warning',
        });
      if (!this.log)
        return this.$message({
          message: '请填写备注',
          type: 'warning',
        });
      this.$axios
        .post(this.$api.user.setCredits, {
          id: this.id,
          credittype: 'balance',
          num: this.rechange_num,
          log: this.log,
        })
        .then(res => {
          if (res.code == 0) {
            this.$emit('getList');
            this.$emit('closePopup');
            if (this.is_detail) {
              this.$parent.$parent.getUserInfo();
              this.$parent.$parent.getBalance();
            }
            this.$message({
              message: `充值成功`,
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped></style>
