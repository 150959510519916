<template>
  <el-dialog title="赠送优惠券" :visible.sync="showCoupon" width="35%" :before-close="cancel">
    <el-form class="rechange" label-width="100px">
      <el-form-item label="赠送优惠券：">
        <el-select size="small" v-model="coupon_id" placeholder="请选择赠送优惠券">
          <el-option v-for="item in couponList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="赠送数量：">
        <el-input v-model="coupon_num"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="comfirmCoupon">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      couponList: [],
    };
  },
  props: ['id', 'coupon_num', 'coupon_id', 'showCoupon', 'is_detail'],
  watch: {
    showCoupon(val) {
      if (val && !this.couponList.length) this.getCouponList();
    },
  },
  methods: {
    cancel() {
      this.$emit('closePopup');
    },
    comfirmCoupon() {
      if (!this.coupon_id)
        return this.$message({
          message: '请选择优惠券',
          type: 'warning',
        });
      if (!this.coupon_num)
        return this.$message({
          message: '请填写赠送数量',
          type: 'warning',
        });
      this.$axios
        .post(this.$api.user.sendCoupons, {
          id: this.id,
          coupons_id: this.coupon_id,
          num: this.coupon_num,
        })
        .then(res => {
          if (res.code == 0) {
            this.cancel();
            if (this.is_detail) {
              this.$parent.$parent.getUserInfo()
              this.$parent.$parent.getCoupons();
            };
            this.$message({
              message: `赠送成功`,
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getCouponList() {
      this.$axios.post(this.$api.user.getCouponList).then(res => {
        if (res.code == 0) {
          this.couponList = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
