<template>
  <el-drawer title="发送联系" :visible.sync="drawer" :direction="direction" :before-close="handleClose" :size="drawerWidth">
    <div class="send-message">
      <el-form ref="form" :rules="rules" :model="addForm" label-width="110px">
        <el-form-item label="发送人：" prop="user_id">
          <el-select v-model="addForm.user_id" size="small">
            <el-option v-for="(item, index) in memberList" :key="index" :label="item.name" :value="item.user_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发送内容：" prop="content">
          <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="addForm.content"></el-input>
        </el-form-item>
        <el-form-item label="是否发送附件：">
          <el-radio-group @change="addForm.is_attachments ? (drawerWidth = '1200px') : (drawerWidth = '650px')" v-model="addForm.is_attachments">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="addForm.is_attachments" label="添加附件：">
          <div class="tips">1.选中附件类型 2.点击添加附件按钮</div>
          <EnclosureComponents :data="addForm.attachments" :nowUpdateIndex="nowUpdateIndex" :nowUpdateItem="nowUpdateItem" :nowAddEnclosure="nowAddEnclosure"></EnclosureComponents>
          <p style="color: #f56c6c">{{ error.attachments }}</p>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="save">发送</el-button>
        </el-form-item>
      </el-form>
      <ShowPhone v-if="addForm.is_attachments" class="showPhone" :data="addForm.attachments"></ShowPhone>
    </div>
  </el-drawer>
</template>

<script>
import { bus } from '@/util/bus';
import EnclosureComponents from '../enterpriseWechat/components/enclosureComponents';
import ShowPhone from '../enterpriseWechat/components/phoneShow';
import { checkAttachments } from '@/util/util.js';
export default {
  components: {
    EnclosureComponents,
    ShowPhone,
  },
  props: ['external_userid'],
  data() {
    return {
      drawerWidth: '650px',
      drawer: !0,
      memberList: [],
      addForm: {
        user_id: '',
        content: '',
        is_attachments: 0,
        attachments: [],
      },
      error: {
        attachments: '',
      },
      rules: {
        user_id: { required: !0, message: '请选择发送人', trigger: 'blur' },
        content: { required: !0, message: '请填写发送内容', trigger: 'blur' },
      },
      nowUpdateItem: null,
      nowUpdateIndex: null,
      nowAddEnclosure: 'image',
    };
  },
  mounted() {
    bus.$on('changeNowUpdate', (item, index) => {
      this.nowUpdateItem = item;
      this.nowUpdateIndex = index;
      this.nowAddEnclosure = item.msgtype;
    });
    bus.$on('deleteThisFj', index => {
      this.addForm.attachments.splice(index, 1);
      this.nowUpdateItem = null;
      this.nowUpdateIndex = null;
      this.nowAddEnclosure = 'image';
    });
  },
  beforeDestroy() {
    bus.$off(['changeNowUpdate', 'deleteThisFj']);
  },
  created() {
    this.getSendMember();
  },
  methods: {
    handleClose() {
      this.$parent.$parent.showSendMessage = !1;
    },
    getSendMember() {
      this.$axios
        .post(this.$api.user.wxwork.getWxworkMember, {
          external_userid: this.external_userid,
        })
        .then(res => {
          if (res.code == 0) {
            this.memberList = res.result;
          }
        });
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let { attachmentsFlag, errorTxt, errIndex } = checkAttachments(this.addForm.attachments);
          if (!attachmentsFlag) {
            this.error.attachments = errorTxt;
            this.nowAddEnclosure = this.addForm.attachments[errIndex].msgtype;
            this.nowUpdateIndex = errIndex;
            this.nowUpdateItem = this.addForm.attachments[errIndex];
            return;
          } else {
            this.error.attachments = '';
          }
          this.$axios
            .post(this.$api.user.wxwork.sendMessage, {
              external_userid: this.external_userid,
              ...this.addForm,
            })
            .then(res => {
              if (res.code == 0) {
                this.handleClose();
                this.$message.success('发送成功');
              } else {
                this.$message.error(res.msg);
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.send-message {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  .el-form {
    width: 600px;
  }
}
/deep/ .el-drawer.rtl{
  transition: all 0.4s;
}
</style>